import request from "@/api/request";
import {toResourcePhoto} from "@/utils";

export default {
    //列表
    getList: async data => request({ url: '/console/goods.Product/getList', data }),
    // 详情
    getDetail: async data => request({ url: '/console/goods.Product/getDetail', data }),

    // 新增
    addProduct: async data => request({ url: '/console/goods.Product/addProduct', data }),
    // 编辑
    editProduct: async data => request({ url: '/console/goods.Product/editProduct', data }),
    // 删除商品
    delProduct: async data => request({ url: '/console/goods.Product/delProduct', data }),

    // 分配店铺列表
    storeList: async data => request({ url: '/console/goods.Product/storeList', data }),
    // 分配店铺保存
    saveStore: async data => request({ url: '/console/goods.Product/saveStore', data }),
    // 选择商品弹窗数据/console/basic.Common/getCommonData
    getProduct: async data => request({ url: '/console/goods.Product/getProduct', data }).then(res=>{
        let data = res.data.list
        data.forEach(elem=>{
            elem.logo = toResourcePhoto(elem.logo)
            elem.items.forEach(v=>{
                v.pic=toResourcePhoto(v.pic)
            })
        })
        res.data.list = data
        return res
    }),
    // 批量操作可售
    batchEnable: async data => request({ url: '/console/goods.Product/batchEnable', data }),
    // 批量操作禁售
    batchStop: async data => request({ url: '/console/goods.Product/batchStop', data }),
    // 批量分配店铺保存
    batchStore: async data => request({ url: '/console/goods.Product/batchStore', data }),
    // 批量删除
    batchDel: async data => request({ url: '/console/goods.Product/batchDel', data }),

    // 获取店铺产品
    getStoreByProduct: async data => request({ url: '/console/goods.Product/getStoreByProduct', data }),
}
