<template>
  <div class="flex">
    <logo/>

    <div class="flex-1 flex items-center justify-between px-24 ">
      <div class="text-101010 text-26 flex-1">浙文眼镜</div>

      <div class="flex items-center">
        <div class="mx-10">
          <div class="cursor-pointer" @click="onClickInfo">系统通知</div>
        </div>

        <div class="px-20 py-12">
          <el-dropdown class="nav_drop">
          <span class="el-dropdown-link ">
            {{ userInfo.admin.realname }}
            <i class="el-icon-arrow-down"/>
          </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="clearCash">清空缓存</el-dropdown-item>
              <el-dropdown-item divided @click.native="changePhone">修改手机号</el-dropdown-item>
              <el-dropdown-item divided @click.native="changePassword">修改密码</el-dropdown-item>
              <el-dropdown-item divided @click.native="handLayOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

      </div>
    </div>
    <el-drawer
      :visible.sync="drawer"
      :modal="false"
      :append-to-body="false"
      :modal-append-to-body="false"
      title="系统通知"
    >

      <div v-for="(elem,i) in infoList" :key="i">
        <div class="py-4 px-10 bg-f2f2f6 font-bold">

          <i class="el-icon-tickets mr-8"/>

          <span>{{ elem.name }}</span>
        </div>
        <div class="px-10">
          <div v-for="(element,j) in elem.list" :key="j" class="py-10 " :class="{'br-t-e6e6e6': 0 !== j}">
            <div
              class="flex px-10 py-4 items-center justify-between cursor-pointer bg-blue"
              @click="onClickEdit(i,j)"
            >
              <span class="flex-1 text-666666">{{ element.name }}</span>

              <span class="text-3291F8">({{ element.num }})</span>
            </div>

            <div class="mt-10 px-10 text-b5b5b5 text-12">{{ element.time }}</div>
          </div>
        </div>
      </div>
    </el-drawer>

    <el-dialog
      :append-to-body="false"
      :before-close="handCancel"
      :close-on-click-modal="false"
      :modal="false"
      :visible.sync="dialog_cash"
      destroy-on-close
      title="提示"
      width="500px"
    >
      <el-form label-position="right" label-width="120px" size="small">
        <el-form-item label="缓存列表">
          <el-select v-model="cashe" multiple placeholder="请选择" value-key="key">
            <el-option
              v-for="item in cacheList"
              :key="item.key"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" type="primary" @click="handSure">确定</el-button>
        <el-button plain size="small" @click="handCancel">取消</el-button>
      </div>
    </el-dialog>
    <!--修改手机号-->

    <el-dialog
      :append-to-body="false"
      :before-close="handChangePhoneCancel"
      :close-on-click-modal="false"
      :modal="false"
      :visible.sync="dialog_phone"
      destroy-on-close
      title="修改手机号"
      width="500px"
    >
      <el-form
        v-show="steep===1"
        ref="steep_one"
        :model="formData_one"
        :rules="steepRulesOne"
        label-position="right"
        label-width="120px"
        size="small"
      >
        <el-form-item label="旧手机号:" prop="phone">
          <el-input v-model="formData_one.phone" class="w-300" disabled/>
        </el-form-item>
        <el-form-item label="短信验证码:" prop="code">
          <div class="flex">
            <el-input v-model="formData_one.code" class="w-200"/>
            <el-button v-if="showCode" class="ml-5" type="primary" @click="getCode(1)">获取验证码</el-button>
            <el-button v-else class="ml-5" type="text">{{ count }}秒后重新获取</el-button>
          </div>
        </el-form-item>
        <el-form-item label="登录密码:" prop="password">
          <el-input
            v-model="formData_one.password"
            class="w-300"
            placeholder="请输入登录密码"
            show-password
            type="password"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-form
        v-show="steep===2"
        ref="steep_two"
        :model="formData_tow"
        :rules="steepRulesTwo"
        label-position="right"
        label-width="120px"
        size="small"
      >
        <el-form-item label="新手机号:" prop="phone">
          <el-input v-model="formData_tow.phone" class="w-300" maxlength="11" placeholder="请输入新手机号"/>
        </el-form-item>
        <el-form-item label="短信验证码:" prop="code">
          <div class="flex">
            <el-input v-model="formData_tow.code" class="w-200"/>
            <el-button v-if="showCode" class="ml-5" type="primary" @click="getCode(2)">获取验证码</el-button>
            <el-button v-else class="ml-5" type="text">{{ count }}秒后重新获取</el-button>
          </div>
        </el-form-item>
        <el-form-item label="设置密码:" prop="password">
          <el-input
            v-model="formData_tow.password"
            class="w-300"
            placeholder="请设置登录密码"
            show-password
            type="password"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button v-if="steep===1" size="small" type="primary" @click="handChangePhone">下一步</el-button>
        <el-button v-if="steep===2" size="small" type="primary" @click="handChangePhone">确定</el-button>
        <el-button plain size="small" @click="handChangePhoneCancel">取消</el-button>
      </div>
    </el-dialog>

    <!--修改密码-->
    <el-dialog
      :append-to-body="false"
      :before-close="handPassCancel"
      :close-on-click-modal="false"
      :modal="false"
      :visible.sync="dialog_password"
      destroy-on-close
      title="修改密码"
      width="500px"
    >
      <el-form ref="form" :model="passwordForm" :rules="rule" label-position="right" label-width="120px" size="small">
        <el-form-item label="短信验证码:" prop="verify">
          <div class="flex">
            <el-input v-model="passwordForm.code" class="w-200"/>
            <el-button v-if="showCode" class="ml-5" type="primary" @click="getCode(1)">获取验证码</el-button>
            <el-button v-else class="ml-5" type="text">{{ count }}秒后重新获取</el-button>
          </div>
        </el-form-item>
        <el-form-item label="新密码:" prop="new_password">
          <el-input
            v-model="passwordForm.new_password"
            class="w-300"
            placeholder="请输入新密码"
            show-password
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" prop="twice_password">
          <el-input
            v-model="passwordForm.twice_password"
            :disabled="passwordForm.new_password===''"
            class="w-300"
            placeholder="请再次输入密码"
            show-password
            type="password"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" type="primary" @click="handPassSure">确定</el-button>
        <el-button plain size="small" @click="handPassCancel">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Logo from '@/layout/logo'
import {logOut} from "@/utils";

export default {
  name: 'index',
  components: {
    Logo
  },
  data() {
    return {
      drawer: false,
      dialog_phone: false,
      dialog_password: false,
      dialog_cash: false,
      showCode: true,
      cacheList: [],
      infoList: [],
      cashe: [],
      userInfo: {
        admin: {
          realname: ''
        }
      },
      time: '',
      count: 60,
      rule: {
        code: [{required: true, message: '必填项', trigger: 'blur'}],
        new_password: [{required: true, message: '必填项', trigger: 'blur'}, {
          min: 6,
          message: '密码长度不得少于6位',
          trigger: 'blur'
        }],
        twice_password: [{required: true, message: '必填项', trigger: 'blur'}]
      },
      formData_one: {
        phone: '',
        code: '',
        password: ''
      },
      formData_tow: {
        sn: '',
        phone: '',
        code: '',
        password: ''
      },
      steepRulesOne: {
        phone: [{required: true, message: '必填项', trigger: 'blur'}],
        code: [{required: true, message: '必填项', trigger: 'blur'}],
        password: [{required: true, message: '必填项', trigger: 'blur'}]
      },
      steepRulesTwo: {
        phone: [{required: true, message: '必填项', trigger: 'blur'}],
        code: [{required: true, message: '必填项', trigger: 'blur'}],
        password: [{required: true, message: '必填项', trigger: 'blur'}]
      },
      passwordForm: {
        phone: '',
        code: '',
        new_password: '',
        twice_password: ''
      },
      steep: 1,
      pathList: [
        [{path: '/order/manage/manage-all', state: '1'}, {path: '/order/manage/manage-all', state: '3'}],
        [{path: '/order/postsale/postsale-all', state: ''}, {
          path: '/order/postsale/postsale-all',
          state: '2'
        }, {path: '/order/postsale/postsale-evaluate', state: ''}],
        [{path: '/order/optometry/optometry-all', state: ''}, {path: '/order/optometry/optometry-evaluate', state: ''}],
        [{path: '/client/manage/manage-list', state: ''}]
      ]
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    fetchInfo() {
      this.$api.home.getList({}).then(res => {
        this.infoList = res.data
      })
    },

    onClickInfo() {
      this.drawer = true
      this.fetchInfo()
    },

    // 获取用户详情
    getUserInfo() {
      this.$api.login.accountConsole({}).then(res => {
        this.userInfo = res.data
        this.passwordForm.phone = res.data.admin.phone || ''
      })
    },

    handLayOut() {
      logOut()
    },

    clearCash() {
      this.getCashList()
      this.dialog_cash = true
    },

    getCashList() {
      this.$api.common.getCache({}).then(res => {
        this.cacheList = res.data.list
      })
    },

    handSure() {
      this.$api.common.delCache({list: this.cashe}).then(res => {
        this.$message.success(res.msg)
        this.handCancel()
      })
    },

    handCancel() {
      this.dialog_cash = false
      this.cashe = ''
    },

    // 获取验证码
    getVerify(type) {
      let params = {
        phone: type === 1 ? this.userInfo.admin.phone : this.formData_tow.phone,
        type: type === 1 ? 'changePwd' : 'newPhone'
      }
      this.$api.login.sendSms(params)
    },

    /*
    * 修改手机号
    * */
    changePhone() {
      this.formData_one.phone = this.userInfo.admin.phone
      this.dialog_phone = true
    },
    handChangePhone() {
      switch (this.steep) {
        case 1:
          this.$refs.steep_one.validate(valid => {
            if (valid) {
              let params = this.formData_one
              this.$api.home.changePhone(params).then(res => {
                this.formData_tow.sn = res.data.sn
                this.showCode = true
                this.count = 60
                clearInterval(this.time)
                this.steep = 2
              })
            }
          })
          break
        case 2:
          this.$refs.steep_two.validate(valid => {
            if (valid) {
              let params = this.formData_tow
              this.$api.home.changeNewPhone(params).then(res => {
                this.$message.success(res.msg)
                this.handLayOut()
                this.dialog_phone = false
              })
            }
          })
          break
      }
    },

    handChangePhoneCancel() {
      this.dialog_phone = false
      this.$refs.steep_one.clearValidate()
      this.$refs.steep_two.clearValidate()
      this.formData_one = this.$options.data().formData_one
      this.formData_two = this.$options.data().formData_two
      this.steep = 1
      clearInterval(this.time)
      this.showCode = true
      this.count = 60
    },
    // 获取短信验证码
    getCode(type) {
      clearInterval(this.time)
      this.showCode = false
      this.getVerify(type)
      this.time = setInterval(() => {
          if (this.count > 0) {
            this.count = this.count - 1
          } else {
            clearInterval(this.time)
            this.showCode = true
            this.count = 60
          }
        },
        1000)
    },

    /*
        * 修改密码
        * */
    changePassword() {
      this.dialog_password = true
    },

    handPassSure() {
      let params = this.passwordForm
      if (params.twice_password !== params.new_password) {
        this.$message.info('两次密码输入不一致！')
      } else {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.$api.login.forgetPwd(params).then(res => {
              this.$message.success(res.msg)
              this.handLayOut()
            })
          }
        })
      }
    },

    handPassCancel() {
      this.dialog_password = false
      this.$refs.form.clearValidate()
      this.count = 60
      clearInterval(this.time)
      this.passwordForm = this.$options.data().passwordForm
    },
    onClickEdit(i, j) {

      let {path,state} = this.pathList[Number(i)][Number(j)]

      if(path){
        this.$router.push({
          path: path,
          query: {
            state: state
          }
        })
      }
      this.drawer = false
    }
  }
}
</script>

<style scoped>
.bg-blue:hover {
  background: #f2f2f1;
  font-weight: 600;
}
</style>
