import request from "@/api/request"

export default {
    // 新增
    addTags: async data => request({ url: '/console/goods.Tags/addTags', data }),
    // 编辑
    editTags: async data => request({ url: '/console/goods.Tags/editTags', data }),
    //删除
    delTags: async data => request({ url: '/console/goods.Tags/delTags', data }),
    // 列表
    getList: async data => request({ url: '/console/goods.Tags/getList', data })
}
