<template>
<div class="st-menu-item">
  <slot
    name="icon"
    class="st-menu-item-icon"
  />

  <div class="st-menu-item-title">{{title}}</div>
</div>
</template>

<script>
export default {
  name: 'index',
  props:{
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
@import "index";

</style>
