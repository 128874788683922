<template>
  <div>
    <div v-for="(elem,index) in menu">
      <st-menu-item
        class="cursor-pointer hover-blue"
        :title="elem.title"
        @click.stop.native="onClickMenu(elem,index)"
        :class="{'first-menu': ind === index }"
      >
        <template #icon>
          <img :src="require(`../../assets/menu-icon/${elem.icon}.png`)"
               style="width: 18px;height: 18px;margin-right: 10px"/>
        </template>
      </st-menu-item>
    </div>
  </div>
</template>

<script>
// import menu from '../../json/menu.json'
import StMenuItem from '@/components/st-menu-item'
import {getSession, setSession} from "@/utils/storage";
import {getAccountInfo} from "@/utils/menu";

export default {
  name: 'index',
  components: {
    StMenuItem
  },
  data() {
    return {
      menu: [],
      ind: ''
    }
  },
  mounted() {
    this.menu = JSON.parse(getSession('menu'))
  },
  methods: {
    /**
     * 点击一级菜单事件
     * @param elem: 菜单的元素参数
     * @param index: 序号
     */
    onClickMenu(elem, index) {
      this.ind = index
      this.$emit('trigger', elem, index)
      if (0 === index) {

        this.$router.push('/survey')
      }
    }
  }
}
</script>

<style scoped>
.first-menu {
  background: #2c3e50 !important;
}
.hover-blue:hover{
  background: #283c4f;
}
</style>
