import request from "@/api/request";

export default {
    // 列表
    getList: async data => request({url: '/console/market.Discount/lists', data}),

    // 新增折扣
    add: async data => request({url: '/console/market.Discount/add', data}),

    // 编辑折扣
    edit: async data => request({url: '/console/market.Discount/edit', data}),

    // 详情
    getDetail: async data => request({url: '/console/market.Discount/detail', data}),

    // 立即结束
    end: async data => request({url: '/console/market.Discount/end', data}),

    // 立即开始
    start: async data => request({url: '/console/market.Discount/start', data}),

    // 删除
    remove: async data => request({url: '/console/market.Discount/remove', data}),

    // 活动数据
    effect: async data => request({url: '/console/market.Discount/effect', data}),
}
