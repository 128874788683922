import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout'

//  解决重复跳转的警告问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        component: () => import('@/views/login'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/',
        name: 'home',
        redirect: '/survey',
        component: layout,
        children: [
            {
                path: '/survey',
                component: () => import('@/views/survey'),
                meta: {
                    title: '首页装修'
                }
            },
// 装饰
            {
                path: '/fitment/personal/personal-home',
                component: () => import('@/views/fitment/personal/personal-home'),
                meta: {
                    title: '首页装修'
                }
            },
            {
                path: '/fitment/personal/personal-nav',
                component: () => import('@/views/fitment/personal/personal-nav'),
                meta: {
                    title: '底部导航'
                }
            },

            {
                path: '/fitment/system/system-market',
                component: () => import('@/views/fitment/system/system-market'),
                meta: {
                    title: '商城'
                }
            },
            {
                path: '/fitment/system/system-center',
                component: () => import('@/views/fitment/system/system-center'),
                meta: {
                    title: '个人中心'
                }
            },
            {
                path: '/fitment/system/system-search',
                component: () => import('@/views/fitment/system/system-search'),
                meta: {
                    title: '搜索页'
                }
            },

            {
                path: '/fitment/pop-up/pop-up-home',
                component: () => import('@/views/fitment/pop-up/pop-up-home'),
                meta: {
                    title: '首页弹窗'
                }
            },
// 订单
            {
                path: '/order/manage/manage-all',
                component: () => import('@/views/order/manage/manage-all'),
                meta: {
                    title: '全部订单'
                }
            },
            {
                path: '/order/manage/manage-info',
                component: () => import('@/views/order/manage/manage-info'),
                meta: {
                    title: '订单详情'
                }
            },
            {
                path: '/order/manage/manage-evaluate',
                component: () => import('@/views/order/manage/manage-evaluate'),
                meta: {
                    title: '订单评价'
                }
            },

            {
                path: '/order/rights/rights-evaluate',
                component: () => import('@/views/order/rights/rights-all'),
                meta: {
                    title: '订单维权'
                }
            },
            {
                path: '/order/rights/rights-info',
                component: () => import('@/views/order/rights/rights-info'),
                meta: {
                    title: '维权详情'
                }
            },

            {
                path: '/order/postsale/postsale-all',
                component: () => import('@/views/order/postsale/postsale-all'),
                meta: {
                    title: '售后订单'
                }
            },
            {
                path: '/order/postsale/postsale-info',
                component: () => import('@/views/order/postsale/postsale-info'),
                meta: {
                    title: '售后详情'
                }
            },
            {
                path: '/order/postsale/postsale-evaluate',
                component: () => import('@/views/order/postsale/postsale-evaluate'),
                meta: {
                    title: '售后评价'
                }
            },

            {
                path: '/order/optometry/optometry-all',
                component: () => import('@/views/order/optometry/optometry-all'),
                meta: {
                    title: '验光预约'
                }
            },
            {
                path: '/order/optometry/optometry-evaluate',
                component: () => import('@/views/order/optometry/optometry-evaluate'),
                meta: {
                    title: '验光评价'
                }
            },
// 商品
            {
                path: '/commodity/storage/storage-list',
                component: () => import('@/views/commodity/storage/storage-list'),
                meta: {
                    title: '商品库'
                }
            },
            {
                path: '/commodity/storage/storage-add',
                component: () => import('@/views/commodity/storage/storage-add'),
                meta: {
                    title: '新增商品'
                }
            },
            {
                path: '/commodity/storage/storage-edit',
                component: () => import('@/views/commodity/storage/storage-edit'),
                meta: {
                    title: '编辑商品'
                }
            },

            {
                path: '/commodity/sale/sale-list',
                component: () => import('@/views/commodity/sale/sale-list'),
                meta: {
                    title: '商品列表'
                }
            },
            {
                path: '/commodity/sale/sale-add',
                component: () => import('@/views/commodity/sale/sale-add'),
                meta: {
                    title: '新增商品'
                }
            },
            {
                path: '/commodity/sale/sale-edit',
                component: () => import('@/views/commodity/sale/sale-edit'),
                meta: {
                    title: '编辑商品'
                }
            },

            {
                path: '/commodity/attr/attr-category',
                component: () => import('@/views/commodity/attr/attr-category'),
                meta: {
                    title: '商品分类'
                }
            },
            {
                path: '/commodity/attr/attr-label',
                component: () => import('@/views/commodity/attr/attr-label'),
                meta: {
                    title: '商品标签'
                }
            },
            {
                path: '/commodity/attr/attr-attribute',
                component: () => import('@/views/commodity/attr/attr-attribute'),
                meta: {
                    title: '商品属性'
                }
            },
            {
                path: '/commodity/attr/attr-attribute-add',
                component: () => import('@/views/commodity/attr/attr-attribute-add'),
                meta: {
                    title: '新增属性'
                }
            },
            {
                path: '/commodity/attr/attr-attribute-edit',
                component: () => import('@/views/commodity/attr/attr-attribute-edit'),
                meta: {
                    title: '编辑属性'
                }
            },
            {
                path: '/commodity/attr/attr-brands',
                component: () => import('@/views/commodity/attr/attr-brands'),
                meta: {
                    title: '商品品牌'
                }
            },
            {
                path: '/commodity/attr/attr-serve',
                component: () => import('@/views/commodity/attr/attr-serve'),
                meta: {
                    title: '商品服务'
                }
            },
            {
                path: '/commodity/attr/attr-unit',
                component: () => import('@/views/commodity/attr/attr-unit'),
                meta: {
                    title: '商品单位'
                }
            },
//客户
            {
                path: '/client/manage/manage-list',
                component: () => import('@/views/client/manage/manage-list'),
                meta: {
                    title: '全部客户'
                }
            },
            {
                path: '/client/manage/manage-info',
                component: () => import('@/views/client/manage/manage-info'),
                meta: {
                    title: '客户详情'
                }
            },

            {
                path: '/client/integral/integral-set',
                component: () => import('@/views/client/integral/integral-set'),
                meta: {
                    title: '积分设置'
                }
            },
// 门店
            {
                path: '/stores/manage/manage-list',
                component: () => import('@/views/stores/manage/manage-list'),
                meta: {
                    title: '门店列表'
                }
            },
            {
                path: '/stores/manage/manage-add',
                component: () => import('@/views/stores/manage/manage-add'),
                meta: {
                    title: '新增门店'
                }
            },
            {
                path: '/stores/manage/manage-edit',
                component: () => import('@/views/stores/manage/manage-edit'),
                meta: {
                    title: '编辑门店'
                }
            },
            {
                path: '/stores/manage/manage-regular',
                component: () => import('@/views/stores/manage/manage-regular'),
                meta: {
                    title: '进店规则'
                }
            },
// 资讯
            {
                path: '/information/manage/manage-list',
                component: () => import('@/views/information/manage/manage-list'),
                meta: {
                    title: '全部资讯'
                }
            },
            {
                path: '/information/manage/manage-add',
                component: () => import('@/views/information/manage/manage-add'),
                meta: {
                    title: '新增资讯'
                }
            },
            {
                path: '/information/manage/manage-edit',
                component: () => import('@/views/information/manage/manage-edit'),
                meta: {
                    title: '编辑资讯'
                }
            },
            {
                path: '/information/manage/manage-category',
                component: () => import('@/views/information/manage/manage-category'),
                meta: {
                    title: '资讯分类'
                }
            },
// 营销
            {
                path: '/marketing/market/market-discount-list',
                component: () => import('@/views/marketing/market/market-discount-list'),
                meta: {
                    title: '限时折扣'
                }
            },
            {
                path: '/marketing/market/market-discount-list-add',
                component: () => import('@/views/marketing/market/market-discount-list-add'),
                meta: {
                    title: '新增折扣商品'
                }
            },
            {
                path: '/marketing/market/market-discount-list-edit',
                component: () => import('@/views/marketing/market/market-discount-list-edit'),
                meta: {
                    title: '编辑折扣商品'
                }
            },
            {
                path: '/marketing/market/market-discount-list-info',
                component: () => import('@/views/marketing/market/market-discount-list-info'),
                meta: {
                    title: '查看折扣商品'
                }
            },

            {
                path: '/marketing/market/market-turntable',
                component: () => import('@/views/marketing/market/market-turntable'),
                meta: {
                    title: '大转盘'
                }
            },
            {
                path: '/marketing/market/market-turntable-add',
                component: () => import('@/views/marketing/market/market-turntable-add'),
                meta: {
                    title: '新建活动'
                }
            },
            {
                path: '/marketing/market/market-turntable-edit',
                component: () => import('@/views/marketing/market/market-turntable-edit'),
                meta: {
                    title: '编辑活动'
                }
            },
            {
                path: '/marketing/market/market-turntable-info',
                component: () => import('@/views/marketing/market/market-turntable-info'),
                meta: {
                    title: '查看活动'
                }
            },
            {
                path: '/marketing/market/market-turntable-data',
                component: () => import('@/views/marketing/market/market-turntable-data'),
                meta: {
                    title: '大转盘-活动数据'
                }
            },


            {
                path: '/marketing/market/market-integral',
                component: () => import('@/views/marketing/market/market-integral'),
                meta: {
                    title: '积分兑换券'
                }
            },
            {
                path: '/marketing/market/market-integral-add',
                component: () => import('@/views/marketing/market/market-integral-add'),
                meta: {
                    title: '积分兑换券-新建活动'
                }
            },
            {
                path: '/marketing/market/market-integral-edit',
                component: () => import('@/views/marketing/market/market-integral-edit'),
                meta: {
                    title: '积分兑换券-编辑活动'
                }
            },
            {
                path: '/marketing/market/market-integral-info',
                component: () => import('@/views/marketing/market/market-integral-info'),
                meta: {
                    title: '积分兑换券-查看活动'
                }
            },
            {
                path: '/marketing/market/market-integral-data',
                component: () => import('@/views/marketing/market/market-integral-data'),
                meta: {
                    title: '积分兑换券-活动数据'
                }
            },

            {
                path: '/marketing/market/market-delivery',
                component: () => import('@/views/marketing/market/market-delivery'),
                meta: {
                    title: '满包邮'
                }
            },
// 支付
            {
                path: '/payment/pay/pay-config',
                component: () => import('@/views/payment/pay/pay-config'),
                meta: {
                    title: '支付配置'
                }
            },
            {
                path: '/payment/manage/manage-detail',
                component: () => import('@/views/payment/manage/manage-detail'),
                meta: {
                    title: '交易明细'
                }
            },
            {
                path: '/payment/cost/cost-detail',
                component: () => import('@/views/payment/cost/cost-detail'),
                meta: {
                    title: '积分明细'
                }
            },
// 设置

            {
                path: '/setting/shop/shop-info',
                component: () => import('@/views/setting/shop/shop-info'),
                meta: {
                    title: '门店信息'
                }
            },
            {
                path: '/setting/manage/manage-account-list',
                component: () => import('@/views/setting/manage/manage-account-list'),
                meta: {
                    title: '账号设置'
                }
            },
            {
                path: '/setting/manage/manage-account-add',
                component: () => import('@/views/setting/manage/manage-account-add'),
                meta: {
                    title: '新增账号'
                }
            },
            {
                path: '/setting/manage/manage-account-edit',
                component: () => import('@/views/setting/manage/manage-account-edit'),
                meta: {
                    title: '编辑账号'
                }
            },

            {
                path: '/setting/manage/manage-role-list',
                component: () => import('@/views/setting/manage/manage-role-list'),
                meta: {
                    title: '角色管理'
                }
            },
            {
                path: '/setting/manage/manage-role-add',
                component: () => import('@/views/setting/manage/manage-role-add'),
                meta: {
                    title: '新增角色'
                }
            },
            {
                path: '/setting/manage/manage-role-edit',
                component: () => import('@/views/setting/manage/manage-role-edit'),
                meta: {
                    title: '编辑角色'
                }
            },

            {
                path: '/setting/system/system-mini',
                component: () => import('@/views/setting/system/system-mini'),
                meta: {
                    title: '小程序配置'
                }
            },
            {
                path: '/setting/system/system-web',
                component: () => import('@/views/setting/system/system-web'),
                meta: {
                    title: '网店设置'
                }
            },
            {
                path: '/setting/system/system-market',
                component: () => import('@/views/setting/system/system-market'),
                meta: {
                    title: '商城设置'
                }
            },
            {
                path: '/setting/system/system-order',
                component: () => import('@/views/setting/system/system-order'),
                meta: {
                    title: '订单设置'
                }
            },
            {
                path: '/setting/system/system-deliver-model',
                component: () => import('@/views/setting/system/system-deliver-model'),
                meta: {
                    title: '运费模板设置'
                }
            },
            {
                path: '/setting/system/system-deliver-model-edit',
                component: () => import('@/views/setting/system/system-deliver-model-edit'),
                meta: {
                    title: '编辑运费模板'
                }
            },
            {
                path: '/setting/system/system-deliver-model-add',
                component: () => import('@/views/setting/system/system-deliver-model-add'),
                meta: {
                    title: '新增运费模板'
                }
            },
            {
                path: '/setting/system/system-log',
                component: () => import('@/views/setting/system/system-log'),
                meta: {
                    title: '操作日志'
                }
            },

            {
                path: '/setting/shop/shop-info',
                component: () => import('@/views/setting/shop/shop-info'),
                meta: {
                    title: '门店信息'
                }
            },
// 下载
            {
                path: '/download/download-list',
                component: () => import('@/views/download/download-list'),
                meta: {
                    title: '下载信息'
                }
            },

        ]
    }
]

const router = new VueRouter({
    routes
})

export default router
