import router from '@/router';
import {sessionClearAll, getToken, getSession, setSession, removeToken} from "@/utils/storage";
import {logOut} from "@/utils";

const whiteList = ['/login'];

router.beforeEach(async (to, from, next) => {
    // 获取管理端标题
    document.title = to.meta.title || '管理端后台';

    //sessionClearAll();
    
    const accountInfo = JSON.parse(getSession('account'))
    const menu = JSON.parse(getSession('menu'))
    const route = JSON.parse(getSession('Router'))
    if(!accountInfo || !menu || !route){
        removeToken()
    }

    // 获取token
    const token = getToken();
    if (token) {
        if (to.path === '/login') {
            next({path: '/survey'});
        } else {
            next();
        }
    } else {
        /* has no token*/
        if (whiteList.indexOf(to.path) !== -1) {
            next();
        } else {
            sessionClearAll();
            next('/login');
        }
    }
});
