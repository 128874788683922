import request from "@/api/request";

export default {
    // 新增
    accountList: async data => request({url: '/console/main.AdminAccount/accountList', data}),
    // 编辑
    deleteAccount: async data => request({url: '/console/main.AdminAccount/deleteAccount', data}),
    //列表
    accountInfo: async data => request({url: '/console/main.AdminAccount/accountInfo', data}),
    // 删除
    editAccount: async data => request({url: '/console/main.AdminAccount/editAccount', data}),
    // 详情
    addAccount: async data => request({url: '/console/main.AdminAccount/addAccount', data}),
    // 新增
    addRole: async data => request({url: '/console/main.Role/addRole', data}),
    // 编辑
    editRole: async data => request({url: '/console/main.Role/editRole', data}),
    //列表
    roleInfo: async data => request({url: '/console/main.Role/roleInfo', data}),
    // 删除
    deleteRole: async data => request({url: '/console/main.Role/deleteRole', data}),
    // 详情
    roleList: async data => request({url: '/console/main.Role/roleList', data}),
    // 选择角色
    selectRole: async data => request({url: '/console/main.AdminAccount/selectRole', data}),
    // 门店
    getStore: async data => request({url: '/console/main.AdminAccount/getStore', data}),
    // 选择商户数据/console/main.AdminAccount/getAccountName
    getAccountName: async data => request({url: '/console/main.AdminAccount/getAccountName', data}),
    // 服务点
    getStation: async data => request({url: '/console/main.AdminAccount/getStation', data}),
    // 获取权限
    getPermission: async data => request({url: '/console/main.AdminAccount/getPermission', data}),
    // 获取权限/console/main.Role/authList
    authList: async data => request({url: '/console/main.Role/authList', data}),
    // 修改状态
    changeState: async data => request({url: '/console/main.AdminAccount/changeState', data}),
}
