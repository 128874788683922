import Cookies from 'js-cookie'

const TokenKey = 'token'

export const getToken = () => Cookies.get(TokenKey)

export const setToken = (token) => Cookies.set(TokenKey, token)

export const removeToken = () => Cookies.remove(TokenKey)

export const setSession = (key,val) => localStorage.setItem(key, JSON.stringify(val))

export const getSession = (key) => JSON.parse(localStorage.getItem(key))

export const sessionClearAll = () => {
    localStorage.clear()
}
