import axios from 'axios'
import {Message, MessageBox} from 'element-ui'
import {getToken} from "@/utils/storage";
import {logOut} from "@/utils";

// 创建一个axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    withCredentials: true, // 携带cookies
    method: 'post',
    timeout: 18000
})

service.interceptors.request.use(
    (config) => {
        config.headers['app-type'] = 'admin'
        if (getToken()) {
            config.headers['zhewen-token'] = getToken()
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    (response) => {
        let res

        if (typeof response.data === 'string') {
            res = JSON.parse(response.data)
        } else {
            res = response.data
        }
        if (res.token) {
            // setToken(res.token)
        }
        if (res.code === 0) {
            return res
        } else if (res.code !== 0) {
            if (res.code === -1) {
                // to re-login
                MessageBox.confirm(
                    '登录失效，您可以取消以停留在此页，或重新登录',
                    '登录失效',
                    {
                        confirmButtonText: '重新登录',
                        showCancelButton: false,
                        type: 'warning'
                    }
                ).then(() => {
                    logOut()
                })
            } else {
                Message({
                    message: res.msg || 'Error',
                    type: 'error',
                    showClose: true,
                    duration: 3 * 1000
                })
            }
            return Promise.reject(new Error(res.msg || 'Error'))
        }
    },
    (error) => {
        Message({
            message: error.msg || 'error',
            type: 'error',
            showClose: true,
            duration: 3 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
