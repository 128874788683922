<template>
  <div class="body">
    <div class="header">
      {{ title }}
    </div>

    <div class="outer">
      <router-view/>
    </div>
  </div>
</template>

<script>
import router from '@/router'

export default {
  name: 'index',
  data() {
    return{
     title: ''
    }
  },
  watch:{
    '$route':{
      handler(val) {
        this.title = val.meta.title
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.body{
  height: 100%;
  .header{
    width: 100%;
    height: 50px;
    background: white;
    border-bottom: 1px solid #f0f2f4;
    line-height: 50px;
    padding-left: 15px;
    box-sizing: border-box;
  }
  .outer{
    background: white;
    height: calc(100% - 60px);
    box-sizing: border-box;
    padding: 15px;
  }
}
</style>
