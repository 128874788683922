import request from "@/api/request";

export default {
    // 列表
    getList: async data => request({ url: '/console/market.Roulette/getList', data }),
    // 详情
    getDetail: async data => request({ url: '/console/market.Roulette/getDetail', data }),
    // 编辑
    edit: async data => request({ url: '/console/market.Roulette/edit', data }),
    // 新增
    add: async data => request({ url: '/console/market.Roulette/add', data }),
    // 删除
    del: async data => request({ url: '/console/market.Roulette/del', data }),
    // 立即结束
    over: async data => request({ url: '/console/market.Roulette/over', data }),
    // 立即开始
    begin: async data => request({ url: '/console/market.Roulette/begin', data }),
    // 活动数据
    getActList: async data => request({ url: '/console/market.Roulette/getActList', data }),
}

