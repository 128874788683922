import request from "@/api/request";

export default {
    // 获取验证码
    sendSms: async data => request({url: '/console/basic.Common/sendSms', data}),
    // 登录
    login: async data => request({url: '/console/basic.Login/login', data}),
    // 路由
    accountConsole(data) {
        return request({url: '/console/basic.Common/accountInfo', data})
    },
    // 忘记密码
    forgetPwd(data) {
        return request({url: '/console/basic.Login/forgetPwd', data})
    }
}
