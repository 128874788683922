import request from "@/api/request";

export default {
    // 新增
    addFreight: async data => request({ url: '/console/main.Freight/addFreight', data }),
    // 编辑
    editFreight: async data => request({ url: '/console/main.Freight/editFreight', data }),
    // 详情
    getDetail: async data => request({ url: '/console/main.Freight/getDetail', data }),
    // 设为默认模板
    setDefault: async data => request({ url: '/console/main.Freight/setDefault', data }),
    // 删除
    delFreight: async data => request({ url: '/console/main.Freight/delFreight', data }),
    // 列表
    getList: async data => request({ url: '/console/main.Freight/getList', data }),
    // 查看商品
    getGoods: async data => request({ url: '/console/goods.Product/getProduct', data }),
}
