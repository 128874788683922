import request from "@/api/request";

export default {
    // 订单-导出
    orders: async data => request({url: '/console/orders.Orders/export', data}),
    // 积分订单-导出
    integral: async data => request({url: '/console/integral.Orders/export', data}),
    // 售后预约订单-导出
    PostSale: async data => request({url: '/console/orders.PostSale/export', data}),
    // 商品申请列表-导出
    GoodsReview: async data => request({url: '/console/goods.GoodsReview/export', data}),
    // 商品库列表-导出
    Product: async data => request({url: '/console/goods.Product/export', data}),
    // 商品列表-导出
    Goods: async data => request({url: '/console/goods.Goods/export', data}),
    // 客户列表-导出
    User: async data => request({url: '/console/user.User/export', data}),
    // 商户列表-导出
    Merchant: async data => request({url: '/console/merchant.Merchant/export', data}),
    // 交易明细-导出
    TradeRecord: async data => request({url: '/console/pay.TradeRecord/export', data}),
    // 提现管理-导出
    CashOut: async data => request({url: '/console/pay.CashOut/export', data}),
    // 积分明细-导出
    Integral: async data => request({url: '/console/pay.Integral/export', data}),
    // 平台收益-导出
    Income: async data => request({url: '/console/pay.Income/export', data}),
    // 我的账户-导出
    Account: async data => request({url: '/console/pay.Account/export', data}),
    // 余额明细-导出
    Balance: async data => request({url: '/console/pay.Balance/export', data}),
    // 账户设置-导出
    AdminAccount: async data => request({url: '/console/main.AdminAccount/export', data}),
}
