import request from "@/api/request"

export default {
    // 新增
    addUnit: async data => request({ url: '/console/goods.Unit/addUnit', data }),
    // 编辑
    editUnit: async data => request({ url: '/console/goods.Unit/editUnit', data }),
    //删除
    delUnit: async data => request({ url: '/console/goods.Unit/delUnit', data }),
    // 列表
    getList: async data => request({ url: '/console/goods.Unit/getList', data })
}
