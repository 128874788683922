import request from "@/api/request";

export default {
    // 分类-添加
    getList: async data => request({url: '/console/orders.Comment/getRepairList', data}),

    // 删除评价
    delComment: async data => request({url: '/console/orders.Comment/delComment', data}),

    // 展示/隐藏评论
    changeState: async data => request({url: '/console/orders.Comment/changeState', data}),

    //回复
    replay: async data => request({url: '/console/orders.Comment/replay', data}),

}
