import request from "@/api/request";

export default {
    // 小程序配置-获取
    Mini: async data => request({url: '/console/main.MiniProgram/getConfig', data}),
    // 小程序配置-保存
    MiniSave: async data => request({url: '/console/main.MiniProgram/saveConfig', data}),

    // 网店配置-获取
    NetShopSet: async data => request({url: '/console/main.NetShop/getConfig', data}),
    // 网店配置设置-保存
    NetShopSave: async data => request({url: '/console/main.NetShop/saveConfig', data}),

    // 订单设置-获取
    Order: async data => request({url: '/console/main.Order/getConfig', data}),
    // 订单设置-保存
    OrderSave: async data => request({url: '/console/main.Order/saveConfig', data}),

    // 商城设置-获取
    Mall: async data => request({url: '/console/main.Mall/getConfig', data}),
    // 商城设置-保存
    MallSave: async data => request({url: '/console/main.Mall/saveConfig', data}),

}
