import request from "@/api/request";

export default {
    // 删除
    delOrder: async data => request({url: '/console/orders.Orders/delOrder', data}),
    // 列表 1：取消订单；2：订单改价；3：修改收货信息；4：发货；5：备货完成；6：核销；7：订单维权
    getList: async data => request({url: '/console/orders.Orders/getList', data}).then(res => {
        const data = res.data

        data.list.forEach(elem => {
            elem.can_cancel = ([1, 5, 6].includes(elem.state) || (3 === elem.state && 0 === elem.de_state)) && !elem.has_ps

            elem.can_chang_price = 1 === elem.state

            elem.can_chang_recive = (((3 === elem.state && 0 === elem.de_state)) || 1 === elem.state) && 1 === elem.delivery

            elem.can_delivery = (3 === elem.state || (4 === elem.state && 2 !== elem.de_state) && 1 === elem.delivery) && !elem.has_ps

            elem.can_goods_finish = 5 === elem.state && 2 === elem.delivery && !data.has_ps
            elem.can_censor = 6 === elem.state && 2 === elem.delivery
            // elem.can_change_express = false
            elem.can_right = elem.has_ps
        })

        res.data = data
        return res
    }),
    // 详情
    getDetail: async data => request({url: '/console/orders.Orders/getDetail', data}).then(res => {
        const data = res.data


        data.can_cancel = ([1, 5, 6].includes(data.state) || (3 === data.state && 0 === data.de_state)) && !data.has_ps

        data.can_chang_price = 1 === data.state

        data.can_chang_recive = (((3 === data.state && 0 === data.de_state)) || 1 === data.state) && 1 === data.delivery

        data.can_delivery = (3 === data.state || (4 === data.state && 2 !== data.de_state) && 1 === data.delivery) && !data.has_ps

        data.can_goods_finish = 5 === data.state && 2 === data.delivery  && !data.has_ps

        data.can_censor = 6 === data.state && 2 === data.delivery

        data.can_right = data.has_ps

        res.data = data

        return res
    }),
    // 关闭订单
    getCloseList: async data => request({url: '/console/orders.Orders/getCloseList', data}),
    // 关闭订单-保存
    closeOrder: async data => request({url: '/console/orders.Orders/closeOrder', data}),
    // 物流信息
    sendOrderInfo: async data => request({url: '/console/orders.Orders/sendOrderInfo', data}),
    // 保存物流信息
    saveDelivery: async data => request({url: '/console/orders.Orders/saveDelivery', data}),
    // 订单改价商品数据
    changePriceInfo: async data => request({url: '/console/orders.Orders/changePriceInfo', data}),

    // 订单改价商品保存
    saveChangePrice: async data => request({url: '/console/orders.Orders/changePrice', data}),

    // 修改订单状态
    changeState: async data => request({url: '/console/orders.Orders/changeState', data}),

    // 修改收货信息
    editAddress: async data => request({url: '/console/orders.Orders/editAddress', data}),
}
