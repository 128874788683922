import request from "@/api/request";

export default {
    // 列表
    getList: async data => request({ url: '/console/orders.PostSale/getList', data }),
    // 详情
    getDetail: async data => request({ url: '/console/orders.PostSale/getDetail', data }),
    // 审核维权
    changeState: async data => request({ url: '/console/orders.PostSale/changeState', data }),
}

