import request from "@/api/request";

export default {
    // 上传图片
    upload: async data => request({url: '/console/basic.Common/upload', data}),
    // 上传视频
    uploadVideo: async data => request({url: '/console/basic.Common/uploadViod', data}),

    // 公共跳转数据
    getCommonData: async data => request({url: '/console/basic.Common/getCommonData', data}),
    // 省份
    getProvince: async data => request({url: '/console/basic.Common/getProvince', data}),
    // 城市
    getCity: async data => request({url: '/console/basic.Common/getCity', data}),
    // 区域
    getArea: async data => request({url: '/console/basic.Common/getArea', data}),
    // 商品一级
    oneList: async data => request({url: '/console/basic.Common/oneList', data}),
    //商品二级
    twoList: async data => request({url: '/console/basic.Common/twoList', data}),
    //商品二级
    bankList: async data => request({url: '/console/basic.Common/bankList', data}),
    // 商品服务列表，
    servicesList: async data => request({url: '/console/basic.Common/servicesList', data}),
    //商品标签
    tagsList: async data => request({url: '/console/basic.Common/tagsList', data}),
    // 商品属性
    getAttrList: async data => request({url: '/console/basic.Common/getAttrList', data}),
    // 商品属性值
    getAttrValuesList: async data => request({url: '/console/basic.Common/getAttrValuesList', data}),
    // 新增属性
    addAttribute: async data => request({url: '/console/basic.Common/addAttribute', data}),
    // 新增属性值
    addAttributeValue: async data => request({url: '/console/basic.Common/addAttributeValue', data}),

    // 运费模板
    expressList: async data => request({url: '/console/basic.Common/expressList', data}),

    // 商品分类
    classList: async data => request({url: '/console/basic.Common/classList', data}),

    // 搜索店铺
    searchStore: async data => request({url: '/console/basic.Common/searchStore', data}),

    // 商品单位
    unitList: async data => request({url: '/console/basic.Common/unitList', data}),
    //商品品牌
    brandList: async data => request({url: '/console/basic.Common/brandList', data}),
    //权限
    accountInfo: async data => request({url: '/console/basic.Common/accountInfo', data}),
    // 缓存列表
    getCache: async data => request({ url: '/console/basic.Redis/getCache', data }),
    // 清除缓存
    delCache: async data => request({url: '/console/basic.Redis/delCache', data}),
    // 区域列表
    regionList: async data => request({url: '/console/basic.Common/regionList', data}),
    // 上传文件
    uploadFile: async data => request({url: '/console/basic.Common/uploadFile', data}),

    // 选择商品弹窗数据/店铺商品
    searchGoods: async data => request({url: '/console/basic.Common/searchGoods', data}),
}
