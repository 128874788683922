<template>
  <div class="bg-white py-10">
    <div v-for="elem in value">
      <div class="text-14 text-center py-6 font-bold">{{ elem.title }}</div>
      <div v-for="(item,i) in elem.controller">
        <template v-if="1!==item.hidden">
          <st-menu-item
            class="cursor-pointer text-12 text-center "
            :class="{'second-menu': ind === item.path}"
            :title="item.title"
            @click.stop.native="onClickJump(item,i)"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import StMenuItem from '@/components/st-menu-item'
import router from '@/router'


export default {
  name: 'index',
  components: {
    StMenuItem
  },
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      // value: [],
      ind: ''
    }
  },

  methods:{
    onClickJump(elem,i) {
       this.ind = elem.path
       // this.ind = elem.url
      router.push({
        path: elem.path
        // this.ind = elem.path
      })
    }
  }
}
</script>

<style scoped>
.second-menu{
  background: #e5e5e5 !important;
}
</style>
