import login from '@/api/login'
import router from "@/router";
/**
 * 拼装菜单
 */
import el from "element-ui/src/locale/lang/el";
import {setSession} from "@/utils/storage";
export  function getAccountInfo() {

    login.accountConsole({}).then(res=>{
        let menu = fiberMenu(res.data.role,0)
        let Router = fiberRouter(res.data.role,0,[])
        menu.unshift({
                title: "概况",
                icon: "survey",
                url: "/survey"})
        setSession('menu',JSON.stringify(menu))
        setSession('Router',JSON.stringify(Router))
        setSession('account',JSON.stringify(res.data.admin))

        router.push({
            path: '/survey'
        })
    })
}

export function fiberMenu(array, level = 0) {
    let list = []
    for (const elem of array) {
        if (0 === level) {
            elem.icon = elem.path
            elem.url = '/' + elem.path
            elem.hidden = 1>elem.module.length? 1: 0
        }

        if (1 === level) {
            elem.group = true
        }

        if (Array.isArray(elem.module) && elem.module.length > 0) {
            fiberMenu(elem.module, 1 + level)
        }

        list.push(elem)
    }

    return list
}


/**
 * 拼装路由
 */

export function fiberRouter(array,level= 0,final = []) {
    let arr = final
    for (const elem of array) {
        if (2 === level) {
            arr.push(elem)
        }
        if(Array.isArray(elem.module) && elem.module.length > 0){
            fiberRouter(elem.module, 1 + level,arr)
        }

        if (Array.isArray(elem.controller) && elem.controller.length > 0) {
            fiberRouter(elem.controller, 1 + level,arr)
        }

    }

    return arr
}
