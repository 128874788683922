<template>
  <div class="lay-out">
    <nav-bar class="lay-out-top"/>

    <div class="lay-out-bottom">
      <lay-menu
        class="lay-out-bottom-left"
        @trigger="onClickMenu"
      />

      <div class="lay-out-bottom-right">
        <second-menu
          id="second"
          v-if="visible_second_menu"
          class="lay-out-bottom-menu"
          :value="second_menu_list"
        />

        <div class="lay-out-route-wrap">
          <second-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/layout/nav-bar/index.vue'
import LayMenu from '@/layout/menu/index.vue'
import SecondMenu from '@/layout/second-menu/index.vue'
import SecondView from '@/layout/second-view/index.vue'

export default {
  name: 'index',
  components: {
    NavBar,
    LayMenu,
    SecondMenu,
    SecondView
  },
  created() {
  },
  mounted() {
  },
  data() {
    return {
      visible_second_menu: false,
      el_id: 'second',
      second_menu_list: []
    }
  },
  watch: {
    visible_second_menu: {
      handler(val) {
        if (val) {
          window.addEventListener('click', this.clickOther)
        } else {
          window.removeEventListener('click', this.clickOther)
        }
      }
    }
  },
  methods: {
    /**
     * 二级菜单展示关闭 注册事件监听
     * @param event
     */
    clickOther(event) {
      let isSelf = document.getElementById(this.el_id).contains(event.target) // 这个是自己的区域
      this.visible_second_menu = isSelf
    },


    /**
     * 点击一级菜单
     */
    onClickMenu(elem, index) {
      if (0 !== index) {
        this.visible_second_menu = true
        this.second_menu_list = elem.module
      } else {
        this.visible_second_menu = false
      }
    }
  }
}
</script>

<style scoped lang="scss">


.lay-out {
  height: 100vh;

  .lay-out-top {
    height: 60px;
    box-sizing: border-box;
    box-shadow: 0 0px 7px 0px #606266;
    position: relative;
    z-index: 10;
  }

  .lay-out-bottom {
    display: flex;
    height: calc(100% - 60px);

    .lay-out-bottom-left {
      background: #001529;
      color: white;
      width: 120px;
    }

    .lay-out-bottom-right {
      display: flex;
      width: calc(100% - 100px);
      background: #f0f2f4;
      overflow: auto;

      .lay-out-bottom-menu {
        min-width: 100px;
        position: relative;
        box-shadow: -2px 0 5px 0px #909399;
      }

      .lay-out-route-wrap {
        flex: 1;
        padding: 15px 10px 0px 10px;
        width: calc(100% - 160px);
      }
    }
  }
}

</style>
