import request from "@/api/request";

export default {
    getList: async data => request({url: '/console/goods.Goods/getList', data}), // 列表
    editGoods: async data => request({url: '/console/goods.Goods/editGoods', data}), // 编辑
    batchDel: async data => request({url: '/console/goods.Goods/batchDel', data}), // 批量删除
    batchStop: async data => request({url: '/console/goods.Goods/batchStop', data}), // 批量下架
    batchEnable: async data => request({url: '/console/goods.Goods/batchEnable', data}), // 批量上架
    batchExpress: async data => request({url: '/console/goods.Goods/batchExpress', data}), // 批量设置配送模板
    getItems: async data => request({url: '/console/goods.Goods/getItems', data}), // 设置价格规格信息
    changePrice: async data => request({url: '/console/goods.Goods/changePrice', data}), // 设置价格保存
    getStock: async data => request({url: '/console/goods.Goods/getStock', data}), // 获取库存信息
    changeStock: async data => request({url: '/console/goods.Goods/changeStock', data}), // 保存库存信息
    delGoods: async data => request({url: '/console/goods.Goods/delGoods', data}), // 删除
    getDetail: async data => request({url: '/console/goods.Goods/getDetail', data}), // 详情
    expressList: async data => request({url: '/console/basic.Common/expressList', data}), // 配送模板列表
}

