import request from "@/api/request";

export default {
    //上传证书
    uploadCertificate: async data => request({url: '/console/basic.Common/uploadCertificate', data}),
    // 交易明细-列表
    TradeRecord: async data => request({url: '/console/pay.TradeRecord/getList', data}),
    // 积分明细-列表
    Integral: async data => request({url: '/console/pay.Integral/getList', data}),
    // 收款设置
    saveConfig: async data => request({url: '/console/pay.Config/saveConfig', data}),
    // 收款设置
    getConfigInfo: async data => request({url: '/console/pay.Config/getInfo', data}),
}
