// 图片上传公用方法，判断类型和大小，上传前压缩图片，show为false是单图上传，为true时是多图上传
import {Message} from "element-ui";
import da from "element-ui/src/locale/lang/da";
import {removeToken, sessionClearAll, setToken} from "@/utils/storage";
import router from "@/router";

export function totalFunction(e, show = false, callback) {
    const file = show ? e : e.file
    const reader = new FileReader()
    const image = new Image()
    const type = file.name.substring(file.name.lastIndexOf('.') + 1)
    const isLt10M = file.size / 1024 / 1024 < 10
    const isLt2M = file.size / 1024 / 1024 < 2
    if (type !== 'png' && type !== 'jpg' && type !== 'jpeg' && type !== 'gif' && type !== 'bmp') {
        Message.error('上传图片格式错误，请重新上传!')
        return false
    } else if (!isLt10M) {
        Message.error('上传图片大小不能超过10M，请重新上传!')
        return false
    } else {
        reader.readAsDataURL(file)
        reader.onload = function (src) {
            image.src = src.target.result
            image.onload = function () {
                let startNum = ''
                let baseStr = ''
                if (!isLt2M) {
                    const canvas = document.createElement('canvas')
                    const context = canvas.getContext('2d')
                    const imgQuality = isLt10M < 10 && isLt10M > 5 ? 0.5 : 0.6 // 压缩百分比
                    const width = image.width * imgQuality
                    const height = image.height * imgQuality
                    canvas.width = width
                    canvas.height = height
                    context.clearRect(0, 0, width, height)
                    context.drawImage(image, 0, 0, width, height)
                    const dataUrl = canvas.toDataURL()
                    // 去除前部格式信息（如果有需求）
                    startNum = dataUrl.indexOf('base64,')
                    startNum = startNum + 7
                    baseStr = dataUrl.slice(startNum)
                } else {
                    startNum = reader.result.indexOf('base64,')
                    startNum = startNum + 7
                    baseStr = reader.result.slice(startNum)
                }
                const formData = {
                    file: JSON.stringify({
                        cont: baseStr,
                        tag: 'goods'
                    })
                }
                callback(formData)
            }
        }
    }
}

// 上传图片公共方法， 判断类型大小
export function videoUpload(e, size, callback) {
    const file = e.file
    const fileSize = file.size / 1024 / 1024 < size
    if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) === -1) {
        Message.error('请上传正确的视频格式')
        store.dispatch('user/setProgress', 0)
        return false
    } else if (!fileSize) {
        Message.error(`视频大小不得超过 ${size}MB!`)
        return false
    } else {
        const formData = new FormData()
        formData.append('video', file)
        callback(formData)
    }
}

// export function uploadFileFn(file) {
//     const isLt10M = file.size / 1024 / 1024 < 10
//     if (!isLt10M) {
//         return message.warning('请上传小于10M的文件')
//     }
//     const type = file.name.substring(file.name.lastIndexOf('.') + 1)
//
//     if (['pdf'].includes(type)) {
//
//         let formData = new FormData()
//
//         formData.append('file', file)
//
//         return new Promise((resolve, reject) => {
//             this.$api.common.uploadFile(formData).then(res => {
//                 resolve(res)
//             }).catch(err => {
//                 reject(err)
//             })
//         })
//     } else {
//         return message.warning('请上传PDF文档！')
//     }
// }

/**
 * 格式化图片
 * @param data: 索要格式化的图片参数，可能未数组或单个字符串
 */
export function toResourcePhoto(data) {
    const empty = {minimal: '', high: '', url: ''}

    if('[object Object]'===Object.prototype.toString.call(data)){
        return data
    }

    if(Array.isArray(data)){
        return empty
    }

    if('[object String]'===Object.prototype.toString.call(data)){
        return {
            minimal: data,
            high: data,
            url: data
        }
    }
}

export function getDefaultTime(num) {
    let array = []
    let end = new Date()
    let start = new Date()
    start.setTime(start.getTime() - 24 * 3600 * 1000 * Number(num))
    array[0] = start.toLocaleDateString().replace(/\//g, '-')
    array[1] = end.toLocaleDateString().replace(/\//g, '-')
    return array
}

/**
 * 退出操作： 清空缓存的路由等内容
 */
export function logOut() {
    removeToken('token')
    router.push({
        path: '/login'
    }).then(()=>{sessionClearAll();})

}
