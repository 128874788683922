<template>
<div class="w-120 bg-fdfdff flex items-center justify-center text-white">
  <img src="../../assets/logo.png" style="width: 50px;height: 50px"/>
</div>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style scoped>
.bg-fdfdff{
  background: #fdfdff;
}
</style>
