import shop from "@/api/shop";
import erp from "@/api/erp";
import order from "@/api/order";
import right from "@/api/right";
import login from "@/api/login";
import common from "@/api/common";
import system from "@/api/Log";
import config from "@/api/config";
import client from "@/api/client";
import account from "@/api/account";
import freight from "@/api/freight";
import payment from "@/api/payment";
import fitment from "@/api/fitment";
import downLoad from "@/api/downLoad";
import postsale from "@/api/postsale";
import turntable from "@/api/turntable";
import optometry from "@/api/optometry";
import information from "@/api/information";
import commodityUnit from "@/api/commodity-unit";
import commodityTags from "@/api/commodity-tags";
import commodityGoods from "@/api/commodity-goods";
import clientIntegral from "@/api/client-integral";
import commodityBrand from "@/api/commodity-brand";
import commodityProduct from "@/api/commodity-product";
import postsaleEvaluate from "@/api/postsale-evaluate";
import commodityService from "@/api/commodity-service";
import commodityCategory from "@/api/commodity-category";
import optometryEvaluate from "@/api/optometry-evaluate";
import commodityAttribute from "@/api/commodity-attribute";
import MarketDelivery from "@/api/market-delivery";
import exportApi from "@/api/export-api";
import coupon from "@/api/coupon";
import discount from "@/api/discount";
import home from "@/api/home";
const api = {
    erp,
    home,
    coupon,
    discount,
    turntable,
    downLoad,
    postsale,
    right,
    shop,
    login,
    order,
    common,
    config,
    system,
    client,
    freight,
    fitment,
    optometry,
    exportApi,
    account,
    payment,
    information,
    commodityUnit,
    commodityTags,
    commodityBrand,
    commodityGoods,
    clientIntegral,
    MarketDelivery,
    commodityService,
    commodityProduct,
    postsaleEvaluate,
    commodityCategory,
    optometryEvaluate,
    commodityAttribute,
}
export default api
