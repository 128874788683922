import request from "@/api/request"
export default {
    // 新增
    addServices: async data => request({ url: '/console/goods.Services/addServices', data }),
    // 编辑
    editServices: async data => request({ url: '/console/goods.Services/editServices', data }),
    //删除
    delServices: async data => request({ url: '/console/goods.Services/delServices', data }),
    // 列表
    getList: async data => request({ url: '/console/goods.Services/getList', data })
}
