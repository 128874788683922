import request from "@/api/request"
export default {
    // 新增
    addBrand: async data => request({ url: '/console/goods.Brand/addBrand', data }),
    // 编辑
    editBrand: async data => request({ url: '/console/goods.Brand/editBrand', data }),
    //删除
    delBrand: async data => request({ url: '/console/goods.Brand/delBrand', data }),
    // 列表
    getList: async data => request({ url: '/console/goods.Brand/getList', data })
}
