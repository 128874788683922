import request from "@/api/request";

export default {
    // 列表
    getList: async data => request({ url: '/console/orders.Repair/getList', data }),
    // 详情
    getDetail: async data => request({ url: '/console/orders.Repair/getDetail', data }),
    // 删除
    delPost: async data => request({ url: '/console/orders.Repair/delPost', data }),
    // 取消售后
    cancel: async data => request({ url: '/console/orders.Repair/cancel', data }),
    // 客户维修到店
    arrival: async data => request({ url: '/console/orders.Repair/arrival', data }),

    // 维修反馈
    feedback: async data => request({ url: '/console/orders.Repair/feedback', data }),
}
