import request from "@/api/request";
export default {
    // 新增
    addAttribute: async data => request({ url: '/console/goods.Attribute/addAttribute', data }),
    // 编辑
    editAttribute: async data => request({ url: '/console/goods.Attribute/editAttribute', data }),
    //删除
    delAttribute: async data => request({ url: '/console/goods.Attribute/delAttribute', data }),
    // 列表
    getList: async data => request({ url: '/console/goods.Attribute/getList', data }),
    // 列表
    getDetail: async data => request({ url: '/console/goods.Attribute/getDetail', data })
}
