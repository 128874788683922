import request from "@/api/request";

export default {
    // 列表
    getList: async data => request({url: '/console/market.Integral/getList', data}),

    // 新增优惠卷
    addCoupon: async data => request({url: '/console/market.Integral/add', data}),

    // 编辑优惠卷
    edit: async data => request({url: '/console/market.Integral/edit', data}),

    // 详情
    getDetail: async data => request({url: '/console/market.Integral/getDetail', data}),

    // 数据列表
    getActList: async data => request({url: '/console/market.Integral/getActList', data}),

    // 立即开始
    begin: async data => request({url: '/console/market.Integral/begin', data}),

    // 立即结束
    over: async data => request({url: '/console/market.Integral/over', data}),

    // 删除
    del: async data => request({url: '/console/market.Integral/del', data}),
}
