import request from "@/api/request"

export default {
    // 新增
    addClass: async data => request({url: '/console/goods.Category/addClass', data}),
    // 编辑
    editClass: async data => request({url: '/console/goods.Category/editClass', data}),
    //删除
    delClass: async data => request({url: '/console/goods.Category/delClass', data}),
    // 列表
    getList: async data => request({url: '/console/goods.Category/getList', data}),
    // 修改状态
    classState: async data => request({url: '/console/goods.Category/classState', data}),
    // 修改排序
    editSort: async data => request({url: '/console/goods.Category/editSort', data})
}
