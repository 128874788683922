import request from "@/api/request";

export default {
    // 首页弹窗-新增
    addPoster: async data => request({ url: '/console/renovation.Poster/addPoster', data }),
    // 首页弹窗-编辑
    editPoster: async data => request({ url: '/console/renovation.Poster/editPoster', data }),
    // 首页弹窗-删除
    delPoster: async data => request({ url: '/console/renovation.Poster/delPoster', data }),
    // 首页弹窗-列表
    getList: async data => request({ url: '/console/renovation.Poster/getList', data }),
    // 搜索页-新增
    addKeywords: async data => request({ url: '/console/renovation.Keywords/addKeywords', data }),
    // 搜索页-编辑
    editKeywords: async data => request({ url: '/console/renovation.Keywords/editKeywords', data }),
    // 搜索页-删除
    delKeywords: async data => request({ url: '/console/renovation.Keywords/delKeywords', data }),
    //搜索页-列表
    searchList: async data => request({ url: '/console/renovation.Keywords/getList', data }),
    // 店铺导航-编辑
    editNav: async data => request({ url: '/console/renovation.DesignNav/editNav', data }),
    // 首页装修-列表
    designList: async data => request({ url: '/console/renovation.DesignHome/getList', data }),
    // 首页装修-编辑
    editHome: async data => request({ url: '/console/renovation.DesignHome/editHome', data }),
    // 店铺导航-列表
    NavList: async data => request({ url: '/console/renovation.DesignNav/getList', data }),
    // 个人中心-编辑
    editCenter: async data => request({ url: '/console/renovation.UserCenter/editCenter', data }),
    // 个人中心-获取
    UserCenterList: async data => request({ url: '/console/renovation.UserCenter/getList', data }),


    // 轮播图保存
    bannerSave: async data => request({ url: '/console/renovation.Banner/save', data }),
    // 轮播图列表
    getBannerList: async data => request({ url: '/console/renovation.Banner/getList', data }),


}
